import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isMobile } from 'mobile-device-detect';
import {
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import {
  Button as MaterialButton,
  Checkbox,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ModalView from '../../components/ModalView';
import Icon from '../../components/Icon';
import './style.scss';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import GooglePlacesAutocomplete from 'react-google-autocomplete';
import { usStates as states } from '../../utils/geographyUtils';
import ModalDialog from '../../components/ModalDialog';
import { addToast as actionAddToast } from '../../actions';
import ErrorLogger from '../../utils/errorLogger';
import { config } from '../../settings';
import LoadingIndicator from '../../components/LoadingIndicator';

export const addressFromComponents = (comp) => {
  const address = {};

  for (let i = 0; i < comp.length; i++) {
    const obj = comp[i];
    // console.log(obj.types);
    if (obj.types.indexOf('street_number') !== -1) {
      address.street_number = obj.long_name;
    } else if (obj.types.indexOf('route') !== -1) {
      address.address1 = `${address.street_number} ${obj.long_name}`;
    } else if (obj.types.indexOf('locality') !== -1 || obj.types.indexOf('postal_town') !== -1) {
      address.city = obj.long_name;
    } else if (obj.types.indexOf('administrative_area_level_1') !== -1) {
      address.state = obj.short_name;
    } else if (obj.types.indexOf('postal_code') !== -1) {
      address.zip = obj.short_name;
    } else if (obj.types.indexOf('country') !== -1) {
      address.country = obj.long_name;
    }
  }
  return address;
};

export function VenueEditor(props) {
  const [venue, setVenue] = useState({});
  const [location, setLocation] = useState({});
  const [selectedName, setSelectedName] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null); // FIXME Is this necessary?
  const [customLocation, setCustomLocation] = useState(false);
  const [onlineOnly, setOnlineOnly] = useState(false);
  const [phone, setPhone] = useState(null);
  const [website, setWebsite] = useState(null);
  const [canBuy, setCanBuy] = useState(false);
  const [canSmoke, setCanSmoke] = useState(false);
  const [canBuyOnline, setCanBuyOnline] = useState(false);
  const [selectedState, setSelectedState] = useState('al');
  const [reporting, setReporting] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [missingName, setMissingName] = useState(false);
  const [missingWebsite, setMissingWebsite] = useState(false);
  const [missingPhone, setMissingPhone] = useState(false);
  const [missingStreet, setMissingStreet] = useState(false);
  const [missingCity, setMissingCity] = useState(false);
  const [missingState, setMissingState] = useState(false);
  const [missingZip, setMissingZip] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const resetDefaults = () => {
    const { venue } = props;
    if (venue && venue.id) {
      setVenue(venue);
      if (venue.location) {
        setLocation(venue.location);
        if (venue.location && venue.location.state) {
          setSelectedState(venue.location.state.toUpperCase());
        }
      }
      setSelectedName(venue.name);
      setPhone(venue.phone);
      setWebsite(venue.website);
      setCanBuy(venue.can_buy);
      setCanBuyOnline(venue.can_buy_online);
      setCanSmoke(venue.can_smoke);
      setReporting(true);
    } else {
      setVenue({});
      setLocation({});
      setSelectedName(null);
      setSelectedAddress(null);
      setCustomLocation(false);
      setPhone(null);
      setWebsite(null);
      setCanBuy(false);
      setCanSmoke(false);
      setCanBuyOnline(false);
      setSelectedState('al');
      setReporting(false);
    }
  };

  useEffect(() => {
    console.log('Using venue:');
    console.log(props.venue);
    resetDefaults();
  }, [props.venue]);

  const toggleOpen = () => {
    const { toggle } = props;
    console.log('Toggling inside modal...');
    if (toggle && typeof toggle === 'function') {
      toggle();
    }
  };

  const closeModal = () => {
    const { onClose } = props;
    console.log('Closing inside modal...');
    if (onClose && typeof onClose === 'function') {
      onClose();
    }
    resetDefaults();
  };

  const onSave = async () => {
    const updatedVenue = { ...venue };
    updatedVenue.name = selectedName;
    updatedVenue.location = location;
    updatedVenue.phone = phone;
    updatedVenue.website = website;
    updatedVenue.can_buy = canBuy;
    updatedVenue.can_buy_online = canBuyOnline || onlineOnly;
    updatedVenue.can_smoke = canSmoke;
    updatedVenue.is_closed = isClosed;
    console.log(updatedVenue);

    if (!updatedVenue.name || updatedVenue.name.length === 0) {
      console.log('Missing required field');
      setMissingName(true);
      return;
    } else {
      setMissingName(false);
    }

    if (onlineOnly && !updatedVenue.website) {
      console.log('Missing required field');
      setMissingWebsite(true);
      return;
    } else {
      setMissingWebsite(false);
    }

    if (!onlineOnly && (!updatedVenue.location || !updatedVenue.location.address1 || updatedVenue.location.address1.length === 0)) {
      console.log('Missing required field');
      setMissingStreet(true);
      return;
    } else {
      setMissingStreet(false);
    }

    if (!onlineOnly && (!updatedVenue.location || !updatedVenue.location.city)) {
      console.log('Missing required field');
      setMissingCity(true);
      return;
    } else {
      setMissingCity(false);
    }

    if (!onlineOnly && (!updatedVenue.location || !updatedVenue.location.state)) {
      console.log('Missing required field');
      setMissingState(true);
      return;
    } else {
      setMissingState(false);
    }

    // FIXME Do we need to require this? I think if city and state are there, we can fill in the blanks
    // if (!onlineOnly && (!updatedVenue.location || !updatedVenue.location.zip)) {
    //   console.log('Missing required field');
    //   setMissingZip(true);
    //   return;
    // } else {
    //   setMissingZip(false);
    // }

    setSubmitting(true);

    // // INFO If venue.id, submit incorrect data, otherwise submit pending venue
    if (updatedVenue.id) {
      await axios.put(`${config.apiEndPoint}/venues/${updatedVenue.id}/report`, updatedVenue).then(() => {
        props.addToast({
          content: (<>Thank you for submitting the details!</>),
          duration: 6000,
        });
        setSubmitting(false);
        closeModal();
      }).catch((err) => {
        setSubmitting(false);
        ErrorLogger.captureException(err); // FIXME Do we need these? Chances are, the backend already logged the error
        ModalDialog.show({
          title: 'Unable to submit venue details',
          message: 'An error occurred while submitting the venue details. If the problem persists, please contact Help & Support from the main menu.',
          buttons: [{
            label: 'Dismiss',
            onClick: () => {
              ModalDialog.close();
            },
          }],
        });
      });
    } else {
      await axios.post(`${config.apiEndPoint}/venues`, updatedVenue).then((res) => {
        if (typeof props.onSaved === 'function') {
          console.log(res.data);
          props.onSaved(res.data);
        }
        setSubmitting(false);
        props.addToast({
          content: (<>Thank you for submitting this venue!</>),
          duration: 6000,
        });
        closeModal();
      }).catch((err) => {
        setSubmitting(false);
        ErrorLogger.captureException(err);
        ModalDialog.show({
          title: 'Unable to submit venue details',
          message: 'An error occurred while submitting the venue details. If the problem persists, please contact Help & Support from the main menu.',
          buttons: [{
            label: 'Dismiss',
            onClick: () => {
              ModalDialog.close();
            },
          }],
        });
      });
    }
  };

  const renderForm = () => (
    <List>
      {(!reporting && !onlineOnly)
        ? (
          <ListItem>
            <ListItemText primary="This is a custom location" secondary={'"Man cave", "Back patio", etc.'} />
            <ListItemSecondaryAction>
              <Switch
                edge="end"
                onChange={() => setCustomLocation(!customLocation)}
                checked={customLocation}
                inputProps={{ 'aria-labelledby': 'switch list custom location' }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ) : null }
      {(!reporting && !customLocation) && (
        <ListItem>
          <ListItemText primary="This is an online-only retailer" secondary="This retailer does not have a physical address or you are unsure of their address" />
          <ListItemSecondaryAction>
            <Switch
              edge="end"
              onChange={() => setOnlineOnly(!onlineOnly)}
              checked={onlineOnly}
              inputProps={{ 'aria-labelledby': 'switch list online-only retailer' }}
            />
          </ListItemSecondaryAction>
        </ListItem>
      )}
      {(customLocation || onlineOnly) && (
        <ListItem>
          <FormGroup style={{ width: '100%', marginBottom: 0 }}>
            <Label for="name" style={{ color: missingName ? '#ef5164' : 'inherit' }}>
              Name
              <sup>*</sup>
            </Label>
            <input
              className={`form-control${missingName ? ' error-placeholder' : ''}`}
              type="text"
              name="name"
              id="name"
              value={selectedName}
              onChange={(e) => setSelectedName(e.target.value)}
            />
          </FormGroup>
        </ListItem>
      )}
      {onlineOnly && (
        <ListItem>
          <FormGroup style={{ width: '100%', marginBottom: 0 }}>
            <Label for="website" style={{ color: missingWebsite ? '#ef5164' : 'inherit' }}>
              Website
              {!reporting && <sup>*</sup>}
            </Label>
            <input
              className={`form-control${missingWebsite ? ' error-placeholder' : ''}`}
              type="text"
              name="website"
              id="website"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </FormGroup>
        </ListItem>
      )}
      {(!customLocation && !onlineOnly) && (
        <>
          <ListItem>
            <FormGroup style={{ width: '100%', marginBottom: 0 }}>
              <Label for="name" style={{ color: missingName ? '#ef5164' : 'inherit' }}>
                Name
                <sup>*</sup>
              </Label>
              {reporting && (
                <input
                  className={`form-control${missingName ? ' error-placeholder' : ''}`}
                  type="text"
                  name="name"
                  id="name"
                  value={selectedName}
                  onChange={(e) => setSelectedName(e.target.value)}
                />
              )}
              {!reporting && (
                <GooglePlacesAutocomplete
                  // apiKey={config.google.api_key}
                  style={{ width: '100%', textTransform: 'capitalize' }}
                  className={`form-control${missingName ? ' error-placeholder' : ''}`}
                  placeholder="Start typing..."
                  value={selectedName}
                  onChange={(e) => {
                    setSelectedName(e.target.value);
                    setMissingName(false);
                  }}
                  onPlaceSelected={(place) => {
                    // console.log('Selected place:');
                    // console.log(place);

                    const lat = place.geometry.location.lat();
                    const lng = place.geometry.location.lng();

                    const comp = place.address_components;
                    const address = addressFromComponents(comp);
                    address.latitude = lat;
                    address.longitude = lng;
                    address.formatted_address = place.formatted_address;

                    setSelectedName(place.name);
                    setWebsite(place.website);
                    setPhone(place.international_phone_number);
                    setLocation(address);
                    setSelectedState(address.state);
                  }}
                  types={['establishment']}
                  fields={[
                    'name',
                    'address_components',
                    'geometry.location',
                    'place_id',
                    'formatted_address',
                    'photos',
                    'url',
                    'website',
                    'opening_hours',
                    'international_phone_number',
                  ]}
                  // componentRestrictions={{country: "us"}}
                />
              )}
            </FormGroup>
          </ListItem>
          <ListItem>
            <FormGroup style={{ width: '100%', marginBottom: 0 }}>
              <Label for="address" style={{ color: missingStreet ? '#ef5164' : 'inherit' }}>
                Address
                <sup>*</sup>
              </Label>
              {reporting && (
                <Input type="text" name="address1" id="address1" value={location.address1} onChange={(e) => setLocation({ ...location, address1: e.target.value })} required />
              )}
              {!reporting && (
                <GooglePlacesAutocomplete
                  style={{ width: '100%', textTransform: 'capitalize' }}
                  className={`form-control${missingStreet ? ' error-placeholder' : ''}`}
                  value={location.address1}
                  onChange={(e) => {
                    const address = { ...location };
                    address.address1 = e.target.value;
                    setLocation(address);
                    setMissingStreet(false);
                  }}
                  onPlaceSelected={(place) => {
                    // console.log(place);

                    const lat = place.geometry.location.lat();
                    const lng = place.geometry.location.lng();

                    const comp = place.address_components;
                    const address = addressFromComponents(comp);
                    address.latitude = lat;
                    address.longitude = lng;

                    setLocation(address);
                    setSelectedState(address.state);
                  }}
                  types={['address']}
                  fields={['address_components', 'geometry.location', 'place_id', 'formatted_address', 'photos']}
                  // componentRestrictions={{country: "us"}}
                />
              )}
            </FormGroup>
          </ListItem>
          <ListItem>
            <Row>
              <Col>
                <FormGroup style={{ width: '100%', marginBottom: 0 }}>
                  <Label for="city" style={{ color: missingCity ? '#ef5164' : 'inherit' }}>
                    City
                    <sup>*</sup>
                  </Label>
                  <Input
                    className={`form-control${missingCity ? ' error-placeholder' : ''}`}
                    type="text"
                    name="city"
                    id="city"
                    value={location.city}
                    onChange={(e) => setLocation({ ...location, city: e.target.value })}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup style={{ width: '100%', marginBottom: 0 }}>
                  <Label for="state" style={{ color: missingState ? '#ef5164' : 'inherit' }}>
                    State
                    <sup>*</sup>
                  </Label>
                  <Input
                    className={`form-control${missingState ? ' error-placeholder' : ''}`}
                    type="select"
                    name="state"
                    id="state"
                    value={selectedState}
                    onChange={(e) => {
                      setLocation({ ...location, state: e.target.value });
                      setSelectedState(e.target.value);
                      console.log('Selected state:');
                      console.log(e.target.value);
                    }}
                  >
                    {states.map((state) => (
                      <option value={state.abbreviation}>{state.abbreviation}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup style={{ width: '100%', marginBottom: 0 }}>
                  {/*<Label for="zip" style={{ color: missingZip ? '#ef5164' : 'inherit' }}>*/}
                  <Label for="zip">
                    ZIP
                    {/*<sup>*</sup>*/}
                  </Label>
                  <Input
                    // className={`form-control${missingZip ? ' error-placeholder' : ''}`}
                    type="text"
                    name="zip"
                    id="zip"
                    value={location.zip}
                    onChange={(e) => setLocation({ ...location, zip: e.target.value })}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ListItem>
          <ListItem>
            <FormGroup style={{ width: '100%', marginBottom: 0 }}>
              <Label for="phone">
                Phone
              </Label>
              <Input
                type="text"
                name="phone"
                id="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </FormGroup>
          </ListItem>
          <ListItem>
            <FormGroup style={{ width: '100%', marginBottom: 0 }}>
              <Label for="website">
                Website
              </Label>
              <Input
                type="text"
                name="website"
                id="website"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </FormGroup>
          </ListItem>
          <ListItem>
            <div>Check all that apply</div>
          </ListItem>
          <ListItem>
            <FormGroup>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox checked={canBuy} />
                  }
                  onChange={() => setCanBuy(!canBuy)}
                  label="Can buy cigars here"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox checked={canSmoke} />
                  }
                  onChange={() => setCanSmoke(!canSmoke)}
                  label="Can smoke cigars here"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox checked={canBuyOnline} />
                  }
                  onChange={() => setCanBuyOnline(!canBuyOnline)}
                  label="Can buy cigars on their website"
                />
              </div>
            </FormGroup>
          </ListItem>
          {reporting && (
            <ListItem>
              <FormControlLabel
                control={
                  <Checkbox checked={isClosed} />
                }
                onChange={() => setIsClosed(!isClosed)}
                label="This place is permanently closed"
              />
            </ListItem>
          )}
        </>
      )}
    </List>
  );

  const { open } = props;
  if (isMobile) {
    return (
      <ModalView
        open={open}
        showFrom="right"
        onClick={toggleOpen}
        onClose={closeModal}
        // path={'/sessions/edit'}
        // parentPath={'/cigars'}
        rightButtons={[
          <MaterialButton aria-label="Submit Cigar" onClick={onSave}>Submit</MaterialButton>,
        ]}
      >
        <div>
          { renderForm() }
          { submitting && <LoadingIndicator overlay /> }
        </div>
      </ModalView>
    );
  }
  return (
    <Modal
      isOpen={open}
      toggle={toggleOpen}
      style={{ maxWidth: 600 }}
      fade
    >
      <div className="modal-header">
        <Button className="close" color="" onClick={closeModal}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        { renderForm() }
        { submitting && <LoadingIndicator overlay /> }
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={closeModal}>Close</Button>
        <Button color="primary" onClick={onSave}>Save</Button>
      </ModalFooter>
    </Modal>
  );
}

VenueEditor.propTypes = {
  venue: PropTypes.object,
  open: PropTypes.bool,
  toggle: PropTypes.func,
  onClose: PropTypes.func,
};

VenueEditor.defaultProps = {
  venue: {},
  open: false,
  toggle: () => {},
  onClose: () => {},
};

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
  };
}

const mapDispatchToProps = (dispatch) => ({
  addToast: (data) => dispatch(actionAddToast(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VenueEditor);
