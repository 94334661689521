import { createAsyncAction } from '../../utils/actionUtils';

export const UPDATE_MESSAGE_LIST = createAsyncAction('UPDATE_MESSAGE_LIST');
export const CREATE_NEW_MESSAGE = 'CREATE_NEW_MESSAGE';
export const UPDATE_CALL_STATUS = 'UPDATE_CALL_STATUS';

export function loadChatMessages(userId, conversationId) {
  console.log('Loading chat messages...');
  return {
    type: UPDATE_MESSAGE_LIST.REQUEST,
    userId,
    conversationId,
  };
}

export function loadChatMessagesRequest(userId, conversationId) {
  return {
    type: UPDATE_MESSAGE_LIST.FETCH,
    userId,
    conversationId,
  };
}

export function loadChatMessagesSuccess(userId, messages, conversationId) {
  return {
    type: UPDATE_MESSAGE_LIST.SUCCESS,
    userId,
    conversationId,
    messages,
  };
}

export function loadChatMessagesFailure(userId, conversationId) {
  return {
    type: UPDATE_MESSAGE_LIST.FAILURE,
    userId,
    conversationId,
  };
}

export function sendChatMessage(message) {
  return {
    type: CREATE_NEW_MESSAGE,
    message,
  };
}

export function updateCallStatus(status) {
  return {
    type: UPDATE_CALL_STATUS,
    status,
  };
}
