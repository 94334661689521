import { put, takeLatest } from 'redux-saga/effects';
import wpApi from '../../utils/wpApi';
import { LOAD_WP_PAGE, loadWpPageSuccess, loadWpPageFail, loadWpPage } from './actions';

export function shouldFetchPage(state) {
  const isLoading = state.get('loading');
  const isExist = state.get('response');
  return !isLoading && !isExist;
}

export function* loadWpPageRequest(action) {
  // Remove filter because of Electron error
  // const wpState = yield select(selectWpPageById(action.pageId));
  // if (wpState && !shouldFetchPage(wpState)) {
  //   return;
  // }

  yield put(loadWpPage(action.pageId));
  try {
    const response = yield wpApi.pages().id(action.pageId);
    yield put(loadWpPageSuccess(action.pageId, response));
  } catch (error) {
    yield put(loadWpPageFail(action.pageId, error));
  }
}

export function* loadWpPageWatcher() {
  yield takeLatest(LOAD_WP_PAGE.REQUEST, loadWpPageRequest);
}

export default [
  loadWpPageWatcher,
];
