import axios from 'axios';
import { takeLatest, put } from 'redux-saga/effects';
import { loadChatMessagesRequest as loadChatMessages, loadChatMessagesFailure, loadChatMessagesSuccess, UPDATE_MESSAGE_LIST } from './actions';
import { config } from '../../settings';

const MAX_MESSAGES_READ_SUMMARY = 100;
// The number of messages loaded into the message area in one read call
const MAX_MESSAGES_READ = 100;

export function* loadChatMessagesRequest(action) {
  const { userId, conversationId } = action;
  console.log('Loading chat messages...');
  // yield put(loadChatMessages(conversationId));
  if (userId) {
    const response = yield axios.get(`${config.apiEndPoint}/users/${userId}/messages`);
    console.log('Messages:');
    console.log(response);
    if (response) {
      yield put(loadChatMessagesSuccess(userId, response.data));
    } else {
      console.log('Unable to get messages...');
      yield put(loadChatMessagesFailure(userId));
    }
  } else if (conversationId) {
    // TODO Switch this to our server - get rid of Mesibo
    if (window.mesibo) {
      console.log('Getting messages in saga...');
      // FIXME Not waiting on the callback function
      const messages = yield new Promise((resolve, reject) => {
        const messageSession = window.mesibo.readDbSession(conversationId, 0, null, (m) => {
          console.log('Got messages in saga...');
          console.log(messageSession.messages);
          resolve(messageSession.messages);
        });
        messageSession.enableReadReceipt(true);
        messageSession.read(MAX_MESSAGES_READ_SUMMARY);
      });
      yield put(loadChatMessagesSuccess(null, messages, conversationId));
    } else {
      console.log('Unable to get messages...');
      yield put(loadChatMessagesFailure(null, conversationId));
    }
  }
}

export function* latestChatMessagesWatcher() {
  yield takeLatest(UPDATE_MESSAGE_LIST.REQUEST, loadChatMessagesRequest);
}

export default [
  latestChatMessagesWatcher,
];
