import { List, fromJS } from 'immutable';
import { LOAD_WP_CATEGORIES } from './actions';

export const initialState = fromJS({
  categories: List(),
  loading: false,
  errorMessage: '',
});
export default function categories(state = initialState, action) {
  switch (action.type) {
    case LOAD_WP_CATEGORIES.FETCH:
      return state.set('loading', true).set('errorMessage', '');
    case LOAD_WP_CATEGORIES.SUCCESS:
      return state.set('loading', false).set('categories', fromJS(action.categories));
    case LOAD_WP_CATEGORIES.FAILURE:
      return state.set('loading', false).set('errorMessage', action.error.message);
    default:
      return state;
  }
}
