import { put, select, takeLatest } from 'redux-saga/effects';
import { LOAD_WP_CATEGORIES, loadWpCategories, loadWpCategoriesSuccess, loadWpCategoriesFail } from './actions';
import { selectCategoryLoading, selectCategories } from './selectors';
import wpApi from '../../utils/wpApi';
import Category from '../../models/Category';

export function* loadWpPostCategories() {
  const wpCategories = yield select(selectCategories());
  const isLoading = yield select(selectCategoryLoading());
  if (isLoading || wpCategories.size > 0) {
    return;
  }

  yield put(loadWpCategories());
  try {
    const response = yield wpApi.categories().exclude(1).param('parent', 0);
    const categories = response.map((item) => new Category(item));
    yield put(loadWpCategoriesSuccess(categories));
  } catch (error) {
    yield put(loadWpCategoriesFail(error));
  }
}

// Individual exports for testing
export function* defaultSaga() {
  yield takeLatest(LOAD_WP_CATEGORIES.REQUEST, loadWpPostCategories);
}

// All sagas to be loaded
export default [
  defaultSaga,
];
