import { fromJS } from 'immutable';
import { CREATE_NEW_MESSAGE, UPDATE_CALL_STATUS, UPDATE_MESSAGE_LIST } from './actions';

const chatState = fromJS({
  messages: {},
});

export default function chatReducer(state = chatState, action) {
  switch (action.type) {
    case UPDATE_MESSAGE_LIST.FETCH:
    case UPDATE_MESSAGE_LIST.SUCCESS:
    case UPDATE_MESSAGE_LIST.FAILURE:
    case CREATE_NEW_MESSAGE.FETCH:
    case CREATE_NEW_MESSAGE.SUCCESS:
    case CREATE_NEW_MESSAGE.FAILURE: {
      console.log('Updating chat messages...');
      const { userId, conversationId, messages } = action;
      const existingMessages = typeof state.get('messages').toJS === 'function' ? state.get('messages').toJS() : {};
      existingMessages[userId || conversationId] = messages;
      setTimeout(() => {
        const $viewport = $('.rui-messenger .rui-messenger-chat .rui-messenger-body .os-viewport');
        if ($viewport) {
          const container = $viewport[0];
          if (container) {
            $('.rui-messenger .rui-messenger-chat .rui-messenger-body .os-viewport').scrollTop(container.scrollHeight);
          }
        }
      }, 100);
      return state.set('messages', existingMessages);
    }
    case UPDATE_CALL_STATUS: {
      return state.set('status', action.status);
    }
    default:
      return state;
  }
}
