import { fromJS } from 'immutable';
import { LOAD_WP_PAGE } from './actions';

const wpPageByIdState = fromJS({
  pageId: 0,
  loading: false,
  errorMessage: '',
  response: undefined,
});
export function wpPageByIdReducer(state = wpPageByIdState, action) {
  switch (action.type) {
    case LOAD_WP_PAGE.FETCH: {
      return state
        .set('pageId', action.pageId)
        .set('loading', true)
        .set('response', undefined);
    }

    case LOAD_WP_PAGE.SUCCESS: {
      return state
        .set('pageId', action.pageId)
        .set('loading', false)
        .set('response', action.response);
    }

    case LOAD_WP_PAGE.FAILURE: {
      return state
        .set('loading', false)
        .set('errorMessage', action.error.message);
    }

    case LOAD_WP_PAGE.CANCEL: {
      return state
        .set('loading', false);
    }

    default:
      return state;
  }
}

const wpPageState = fromJS({});
export default function wpPageReducer(state = wpPageState, action) {
  switch (action.type) {
    case LOAD_WP_PAGE.FETCH:
    case LOAD_WP_PAGE.SUCCESS:
    case LOAD_WP_PAGE.FAILURE:
    case LOAD_WP_PAGE.CANCEL: {
      const pageById = state.get(action.pageId);
      return state.set(action.pageId, wpPageByIdReducer(pageById, action));
    }
    default:
      return state;
  }
}
