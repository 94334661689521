import { createSelector } from 'reselect';

export const selectWpCategoryDomain = () => (state) => state.getIn(['global', 'wpCategory']);

export const selectCategoryLoading = () => createSelector(
  selectWpCategoryDomain(),
  (state) => state.get('loading')
);

export const selectCategories = () => createSelector(
  selectWpCategoryDomain(),
  (state) => state.get('categories')
);
