import { createAsyncAction } from '../../utils/actionUtils';

export const CHANGE_WP_POST_LIST = 'CHANGE_WP_POST_LIST';
export const LOAD_WP_CATEGORIES = createAsyncAction('LOAD_WP_CATEGORIES');

export function changeWpPostList(category) {
  return {
    type: CHANGE_WP_POST_LIST,
    category,
  };
}

export function requestWpCategories() {
  return {
    type: LOAD_WP_CATEGORIES.REQUEST,
  };
}

export function loadWpCategories() {
  return {
    type: LOAD_WP_CATEGORIES.FETCH,
  };
}

export function loadWpCategoriesSuccess(categories) {
  return {
    type: LOAD_WP_CATEGORIES.SUCCESS,
    categories,
  };
}

export function loadWpCategoriesFail(error) {
  return {
    type: LOAD_WP_CATEGORIES.FAILURE,
    error,
  };
}
