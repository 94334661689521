import { createAsyncAction } from '../../utils/actionUtils';

export const LOAD_WP_PAGE = createAsyncAction('LOAD_WP_PAGE');

export function requestWpPage(pageId) {
  return {
    type: LOAD_WP_PAGE.REQUEST,
    pageId,
  };
}

export function loadWpPage(pageId) {
  return {
    type: LOAD_WP_PAGE.FETCH,
    pageId,
  };
}

export function loadWpPageSuccess(pageId, response) {
  return {
    type: LOAD_WP_PAGE.SUCCESS,
    pageId,
    response,
  };
}

export function loadWpPageFail(pageId, error) {
  return {
    type: LOAD_WP_PAGE.FAILURE,
    pageId,
    error,
  };
}
